// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-blog-post-index-js": () => import("./../../../src/components/pages/BlogPost/index.js" /* webpackChunkName: "component---src-components-pages-blog-post-index-js" */),
  "component---src-components-pages-job-post-index-js": () => import("./../../../src/components/pages/JobPost/index.js" /* webpackChunkName: "component---src-components-pages-job-post-index-js" */),
  "component---src-pages-10-x-why-js": () => import("./../../../src/pages/10x-why.js" /* webpackChunkName: "component---src-pages-10-x-why-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-article-js": () => import("./../../../src/pages/article.js" /* webpackChunkName: "component---src-pages-article-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-hiring-js": () => import("./../../../src/pages/hiring.js" /* webpackChunkName: "component---src-pages-hiring-js" */),
  "component---src-pages-human-resources-js": () => import("./../../../src/pages/human-resources.js" /* webpackChunkName: "component---src-pages-human-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kd-js": () => import("./../../../src/pages/kd.js" /* webpackChunkName: "component---src-pages-kd-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-modules-js": () => import("./../../../src/pages/modules.js" /* webpackChunkName: "component---src-pages-modules-js" */),
  "component---src-pages-presentationportal-js": () => import("./../../../src/pages/presentationportal.js" /* webpackChunkName: "component---src-pages-presentationportal-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sharing-js": () => import("./../../../src/pages/sharing.js" /* webpackChunkName: "component---src-pages-sharing-js" */)
}

